.current {
  .currentTopBar {
    margin-bottom: 2em;

    .wind {
      margin-right: 3px;
    }
  }

  .currentBottomBar {
    .currentFeelsLike {
      padding-left: 0 !important;
    }

    @media (max-width: 400px) {
      .temp-summary {
        clear: both;
      }
    }
  }
}
