@import '../../components/common/variables.less';

.header {
  min-height: 94vh;
  padding-top: 1em;
  padding-bottom: 1em;
  align-items: center;
  justify-content: center;
  font-size: @default-font-size;
  color: @default-font-color;
}
