@import 'components/common/variables.less';
@import 'components/common/helpers.less';

body {
  margin: 0;
  font-family: 'Cantarell', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans',
  'Droid Sans', 'Helvetica Neue', sans-serif!important;
  -webkit-font-smoothing: antialiased!important;
  -moz-osx-font-smoothing: grayscale!important;
  font-weight: 200!important;
  background-color: @default-bg!important;

  .App {
    text-align: center;
    background-color: @default-bg;

    .navbar-text {
      padding-right: 1em;
      padding-left: 1em;
    }

    .custom-switch {
      margin-right: -0.75em;
    }
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
}
