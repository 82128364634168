@import '../common/variables.less';
@import '../common/columns.less';

@overview-mult: 1;
.overview (@mult, @zoom: 1) {
  zoom: @zoom;
  .overviewBar {
    padding: calc(@mult * 0.5em) 0 calc(@mult * 0.5em) 0;
    margin-top: calc(@mult * 2em);
    line-height: 20px;
  }
  .overviewTics {
    padding: calc(@mult * 0.25em) 0 0 0;
    -ms-flex: 0 0 calc(@mult * 2em);
    flex: 0 0 calc(@mult * 2em);
    div { border-left: 1px solid #999; }
    .even { height: 5px; }
    .odd { height: 10px; }
  }
  .overviewDetailsRow {
    margin-left: calc(@mult * -2em);
    .overviewDetails {
      padding: 0 0 0 0;
      -ms-flex: 0 0 calc(@mult * 4em);
      flex: 0 0 calc(@mult * 4em);
      text-align: left;
      .overviewDetailsTime { font-size: calc(@mult * 0.75em); }
      .overviewDetailsTemp { font-size: calc(@mult * 1.25em); }
    }
    .first {
      margin-left: calc(@mult * -0.5em);
      margin-right: calc(@mult * -0.5em);
    }
  }
}
.dailyOverview {
  font-size: initial;
  .clear {
    background-color: @clear-bg;
  }
  .partlyCloudy {
    background-color: @partly-cloudy-bg;
  }
  .mostlyCloudy {
    background-color: @mostly-cloudy-bg;
  }
  .lightRain {
    background-color: @light-rain-bg;
  }
  .rain {
    background-color: @rain-bg;
  }
  .overview(1);
  @media (max-width: 400px) {
    .overview(1, 0.5);
    .overviewBar {
      line-height: 1px;
    }
    &.container {
      max-width: 400px;
    }
  }
  @media (max-width: 1000px) and (min-width: 400px) {
    .overview(1, 0.75);
    .overviewBar {
      line-height: 1px;
    }
    &.container {
      max-width: 1200px;
    }
  }
  @media (min-width: 1200px) {
    .overview(1, 1.25);
    &.container {
      max-width: 960px;
    }
  }
  @media (min-width: 2400px) {
    .overview(1, 1.5);
    &.container {
      max-width: 1240px;
    }
  }
}
